
import UserFooterNav from '@/components/userFooterNav.vue'
import NoneContent from '@/components/noneContent.vue'
// import { getOnLineList, getOfflineList } from '@/api/order'
import orderApi from '@/http/apis/order'
import { defineComponent, onMounted, reactive, toRefs, onActivated } from 'vue'
import { weixinPay } from '@/plugins/weixinPay'
import { isWeixinBrowser } from '@/plugins/isWeixinBrowser'
import { useRouter } from 'vue-router'
import { OrderPaymentReq } from '@/types/requestType'
import cookies from 'js-cookie'
interface Status {
  status: string
  statusName: string
}
export default defineComponent({
  name: 'Order',
  components: {
    UserFooterNav,
    NoneContent,
  },
  setup() {
    let onPage = 1 // 商城当前页码
    let offPage = 1 // 线下当前页码
    const item = 10
    let getList = 0
    let onRefresh = false
    // let status: string
    // const initStatusList = [{ status: '', statusName: '全部' }]
    let offRefresh = false
    // let offStatus: number
    const router = useRouter()
    const statusList = [
      {
        status: '',
        statusName: '全部',
      },
      {
        status: 'unpaid',
        statusName: '待支付',
      },
      {
        status: 'onWay',
        statusName: '进行中',
      },
      {
        status: 'finish',
        statusName: '已完成',
      },
      {
        status: 'cancel',
        statusName: '已取消',
      },
      {
        status: 'closed',
        statusName: '已终止',
      },
    ]
    const offStatusList = [
      {
        status: '',
        statusName: '全部',
      },
      {
        status: 'onWay',
        statusName: '未完成',
      },
      {
        status: 'finish',
        statusName: '已完成',
      },
    ]
    const state = reactive({
      index: 0, // tab显示商城/线下
      keyword: '', // 线下订单号
      orderNo: '', // 商城订单号
      // onPage: 1, // 商城当前页码
      onLoading: false, // 商城loading状态
      onFinished: false, // 商城数据是否加载完成
      orderList: [], // 商城订单列表
      statusList: [] as Status[], // 商城订单状态
      // offPage: 1, // 线下当前页码
      offLoading: false, // 线下loading状态
      offFinished: false, // 线下数据是否加载完成
      offOrderList: [], // 线下订单列表
      status: '', // 线上订单状态
      offStatus: -1, // 线下订单状态
    })
    // 获取商城订单
    const loadListOnline = async () => {
      try {
        const { data } = await orderApi.getOrderList({
          pageNum: onPage,
          pageSize: item,
          orderNumber: state.orderNo,
          orderStatus: state.status,
        })
        data.list && (state.orderList = state.orderList.concat(data.list))
        // data.statusCn && (state.statusList = initStatusList.concat(data.statusCn))
        onPage * item >= Number(data.total) ? (state.onFinished = true) : (state.onFinished = false)
        onPage === 1 && (getList += 1)
        console.log(data.orderList)
      } catch (error) {
        console.log(error)
      }
      state.onLoading = false
      onRefresh = false
    }
    // 获取线下订单
    const loadListOffline = async () => {
      try {
        const { data } = await orderApi.getOffOrderList({
          pageNum: onPage,
          pageSize: item,
          orderNumber: state.keyword,
          orderStatus: state.offStatus ? state.offStatus : '',
        })
        data.list && (state.offOrderList = state.offOrderList.concat(data.list))
        offPage * item >= Number(data.total)
          ? (state.offFinished = true)
          : (state.offFinished = false)
        offPage === 1 && (getList += 1)
      } catch (error) {
        console.log(error)
      }
      state.offLoading = false
      offRefresh = false
    }
    // 商城订单加载
    const loadOnline = (): void => {
      if (state.onLoading) {
        return
      }
      state.onLoading = true
      if (onRefresh) {
        onPage = 1
        state.orderList = []
      } else {
        onPage += 1
      }
      loadListOnline()
    }
    // 线下订单加载
    const loadOffline = (): void => {
      if (state.offLoading) {
        return
      }
      state.offLoading = true
      if (offRefresh) {
        offPage = 1
        state.offOrderList = []
      } else {
        offPage += 1
      }
      loadListOffline()
    }
    // 搜索/刷新订单
    const reGetList = (): void => {
      if (state.index === 0) {
        onRefresh = true
        loadOnline()
      } else {
        offRefresh = true
        // state.offLoading = true
        loadOffline()
      }
    }
    // 订单状态筛选
    const statusClick = (name: number | string): void => {
      if (state.index === 0) {
        state.status = name as string
        // status = state.statusList[name].status
      } else {
        state.offStatus = name as number
        // offStatus = offStatusList[name].status
      }
      reGetList()
    }
    // 标签切换
    const tabClick = (name: number): void => {
      state.index = name
      // name === 0 ? (onRefresh = true) : (offRefresh = true)
    }
    // 立即支付
    const immediatelyPay = (orderNumber: string) => {
      const DATA: OrderPaymentReq = {
        orderNumber,
        jumpUrl: `${window.location.origin}/order`,
        isForbidBack: 0,
        isWxPay: isWeixinBrowser() ? 'true' : 'false',
      }
      if (isWeixinBrowser()) {
        DATA.openid = cookies.get('YHD-fa_openid')
      }
      orderApi
        .orderPay(DATA)
        .then((res) => {
          if (isWeixinBrowser()) {
            weixinPay(res.data)
              .then((res) => {
                console.log(res)
              })
              .catch((err) => {
                console.log(err)
                router.push('/order')
              })
          } else {
            window.location.href = res.data.pageUrl
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
    onMounted(() => {
      getList = 0
    })
    onActivated(() => {
      // state.index = 0
      if (getList < 2) {
        loadListOnline()
        loadListOffline()
      }
    })
    return {
      ...toRefs(state),
      statusList,
      offStatusList,
      loadOnline,
      loadOffline,
      reGetList,
      statusClick,
      tabClick,
      immediatelyPay,
    }
  },
})
